import Vue from 'vue'
import VueRouter from 'vue-router'
// import HomeView from '../views/HomeView.vue'
import Index from '../views/index.vue'
// import Layout from '../views/layout/layout.vue'

Vue.use(VueRouter)

const routes = [
  // {
  //   path: '/',
  //   name: 'home',
  //   component: HomeView
  // },
  // {
  //   path: '/',
  //   component: Layout,
  //   redirect: 'index',
  //   children: [
  //     {
  //       path: 'index',
  //       component: () => import('@/views/index'),
  //       name: 'Index'
  //       // meta: { title: '首页', icon: 'dashboard', affix: true }
  //     }
  //   ]
  // },
  {
    path: '/',
    name: 'index',
    component: Index,
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/cooperation',
    component: () => import('@/views/cooperation'),
    hidden: true
  },
  {
    path: '/science',
    component: () => import('@/views/science'),
    hidden: true
  },
  {
    path: '/firm',
    component: () => import('@/views/firm'),
    hidden: true
  },
  {
    path: '/joinmy',
    component: () => import('@/views/joinmy'),
    hidden: true
  },
  {
    path: '/articleList',
    component: () => import('@/views/article-list'),
    hidden: true
  },
  {
    path: '/articleDetail',
    component: () => import('@/views/article-detail'),
    hidden: true
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
