<template>
  <div id="app">
    <!-- <Header></Header> -->
    <div class="top" :class="{ is_fixed: isFixed }" v-if="ispc == true">
      <div style="display: flex; width: 20%">
        <div>
          <!-- <img src="/image/ic.jpg" style="border-radius: 10px; width: 50px; height: 50px" /> -->
          <img src="/image/ic.png" style="border-radius: 10px; width: 50px; height: 50px" />
        </div>
        <div style="
            margin-left: 10px;
            font-size: 30px;
            font-weight: bold;
            color: #000;
          ">
          众聚生活
        </div>
      </div>
      <div style="width: 60%; display: flex; justify-content: center">
        <el-menu :default-active="$route.path" :router="true" class="el-menu-demo" mode="horizontal" text-color="#000"
          active-text-color="#FF591D">
          <el-menu-item index="/">首页</el-menu-item>
          <el-menu-item index="/cooperation">和众聚生活合作</el-menu-item>
          <el-menu-item index="/science">我们的科技</el-menu-item>
          <el-menu-item index="/firm">企业社会责任</el-menu-item>
          <!-- <el-submenu index="4">
            <template slot="title">企业社会责任</template>
<el-menu-item index="/firm">选项1</el-menu-item>
<el-menu-item index="4-2">选项2</el-menu-item>
<el-menu-item index="4-3">选项3</el-menu-item>
<el-submenu index="4-4">
  <template slot="title">选项4</template>
  <el-menu-item index="4-4-1">选项1</el-menu-item>
  <el-menu-item index="4-4-2">选项2</el-menu-item>
  <el-menu-item index="4-4-3">选项3</el-menu-item>
</el-submenu>
</el-submenu> -->
          <!-- <el-submenu index="5">
            <template slot="title">众聚生活公益</template>
            <el-menu-item index="5-1">选项1</el-menu-item>
            <el-menu-item index="5-2">选项2</el-menu-item>
            <el-menu-item index="5-3">选项3</el-menu-item>
            <el-submenu index="5-4">
              <template slot="title">选项4</template>
              <el-menu-item index="5-4-1">选项1</el-menu-item>
              <el-menu-item index="5-4-2">选项2</el-menu-item>
              <el-menu-item index="5-4-3">选项3</el-menu-item>
            </el-submenu>
          </el-submenu> -->
          <!-- <el-menu-item index="6">投资者关系</el-menu-item> -->
          <el-menu-item index="/joinmy">加入我们</el-menu-item>
        </el-menu>
      </div>
      <!-- <div style="width: 20%;">
        <el-menu
          :default-active="$route.path"
          :router="true"
          class="el-menu-demo"
          mode="horizontal"
          text-color="#000"
          active-text-color="#ffd04b"
        >
          <el-submenu index="1">
            <template slot="title">下载和关注</template>
            <el-menu-item index="1-1">选项1</el-menu-item>
            <el-menu-item index="1-2">选项2</el-menu-item>
            <el-menu-item index="1-3">选项3</el-menu-item>
            <el-submenu index="1-4">
              <template slot="title">选项4</template>
              <el-menu-item index="1-4-1">选项1</el-menu-item>
              <el-menu-item index="1-4-2">选项2</el-menu-item>
              <el-menu-item index="1-4-3">选项3</el-menu-item>
            </el-submenu>
          </el-submenu>
          <el-submenu index="2">
            <template slot="title">简体中文</template>
            <el-menu-item index="2-1">选项1</el-menu-item>
            <el-menu-item index="2-2">选项2</el-menu-item>
            <el-menu-item index="2-3">选项3</el-menu-item>
            <el-submenu index="2-4">
              <template slot="title">选项4</template>
              <el-menu-item index="2-4-1">选项1</el-menu-item>
              <el-menu-item index="2-4-2">选项2</el-menu-item>
              <el-menu-item index="2-4-3">选项3</el-menu-item>
            </el-submenu>
          </el-submenu>
        </el-menu>
      </div> -->
    </div>
    <!-- <nav>
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </nav> -->
    <div class="andio" v-if="isandio == true">
      <div class="andiotop">
        <div style="display: flex">
          <div style="margin-right: 5px;">
            <img src="/image/ic.png" style="border-radius: 10px; width: 30px; height: 30px" />
          </div>
          <div style="font-size: 18px;font-weight: bold;display: flex;align-items: center;">众聚生活</div>
        </div>
      </div>
      <div>
        <div style="position: fixed;top: 20px;left: 20px;">
          <i class="el-icon-s-operation" style="color: white;font-size: 25px;" @click="drawer = true"></i>
        </div>
        <el-drawer :visible.sync="drawer" :direction="direction" size="200px" :show-close="false">
          <div  style="padding: 0px 0px 0px 10px;">
            <div style="display: flex;">
              <div style="margin-right: 5px;">
                <img src="/image/ic.png" style="border-radius: 10px; width: 30px; height: 30px" />
              </div>
              <div style="font-size: 18px;font-weight: bold;display: flex;align-items: center;">众聚生活</div>
            </div>
            <div style="width: 100%;">
              <el-row class="tac">
                <el-col :span="12">
                  <el-menu :default-active="$route.path" :router="true" class="el-menu-vertical-demo"  @select="closepage()" @open="handleOpentab" @close="handleClosetab">
                    <!-- <el-submenu index="1">
                      <template slot="title">
                        <span>企业社会责任</span>
                      </template>
                      <el-menu-item-group>
                        <template slot="title">和众聚合作</template>
                        <el-menu-item index="1-1">选项1</el-menu-item>
                        <el-menu-item index="1-2">选项2</el-menu-item>
                      </el-menu-item-group>
                    </el-submenu> -->
                    <el-menu-item index="/">
                      <span slot="title">首页</span>
                    </el-menu-item>
                    <el-menu-item index="/cooperation">
                      <span slot="title">和众聚合作</span>
                    </el-menu-item>
                    <el-menu-item index="/science">
                      <span slot="title">我们的科技</span>
                    </el-menu-item>
                    <el-menu-item index="/firm">
                      <span slot="title">企业社会责任</span>
                    </el-menu-item>
                    <el-menu-item index="/joinmy">
                      <span slot="title">加入我们</span>
                    </el-menu-item>
                  </el-menu>
                </el-col>
              </el-row>
            </div>
          </div>
        </el-drawer>
      </div>
    </div>
    <router-view />
  </div>
</template>
<script>
// import Header from '../components/top.vue'
export default {
  // components: {
  //   Header
  // },
  data() {
    return {
      isFixed: false,
      offsetTop: 0,
      ispc: false,
      isandio: false,

      drawer: false,
      direction: 'ltr',
    };
  },
  mounted() {
    if (this.isMobile()) {
      console.log('app.vue')
      this.isandio = true;
      // this.$router.replace('/m_index');
    } else {
      this.ispc = true;
      // this.$router.replace('/pc_index');
      window.addEventListener("scroll", this.initHeight);
     this.$nextTick(() => {
      this.offsetTop = document.querySelector("#boxFixed").offsetTop;
    });
    }
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    closepage(){
        this.drawer=false
    },
    isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
    initHeight() {
      var scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      this.isFixed = scrollTop > this.offsetTop ? true : false;
    },
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
      // this.$router.push(key);
      if (this.$route.path !== key) {
        this.$router.push(key);
      }
      // this.$router.push(key).catch(err => {
      //     if (err.name !== 'NavigationDuplicated') {
      //       // 处理其他类型的错误
      //       throw err;
      //     }
      //     // 对于重复的导航，这里可以什么也不做
      //   });
      // if (keyPath == 1) {
      //   if (this.$route.path !== "/") {
      //     this.$router.push("/");
      //   }
      //   // this.$router.push('/')
      //   //         this.$router.push('/').catch(err => {
      //   //   if (err.name !== 'NavigationDuplicated') {
      //   //     // 处理其他类型的错误
      //   //     throw err;
      //   //   }
      //   //   // 对于重复的导航，这里可以什么也不做
      //   // });
      // } else if (keyPath == 2) {
      //   if (this.$route.path !== "/cooperation") {
      //     this.$router.push("/cooperation");
      //   }
      //   // this.$router.push('/cooperation')
      //   // this.$router.push("/cooperation").catch((err) => {
      //   //   if (err.name !== "NavigationDuplicated") {
      //   //     // 处理其他类型的错误
      //   //     throw err;
      //   //   }
      //   //   // 对于重复的导航，这里可以什么也不做
      //   // });
      // }
    },
    handleOpentab(key, keyPath) {
        console.log(key, keyPath);
      },
      handleClosetab(key, keyPath) {
        console.log(key, keyPath);
      }
  },
};
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  color: #2c3e50;
}
body{
   margin: 0 !important;
 }
.el-dropdown-menu__item,
.el-menu-item {
  font-size: 15px !important;
  padding: 0px 35px !important;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}

.top {
  padding: 20px;
  /* background-color: #fff; */
  /* backdrop-filter: blur(20px); */
  display: flex;
  background-color: rgba(255, 255, 255, 0.6);
}

.is_fixed {
  position: fixed;
  top: 0;
  z-index: 999;
  width: 100%;
}

.el-menu {
  background-color: transparent !important;
}

.el-menu.el-menu--horizontal {
  border: 0px !important;
}

.andiotop {
  height: 60px;
  background-color: #ffc583;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.el-col-12{
  width: 100% !important;
}
</style>
